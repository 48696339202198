/* ============= Base ============= */

/*********** Theme Generic **********/
body { 
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $theme-text-color-secondary;
    
}

h1, h2, h3, h4, h5, h6 {
	color: $theme-text-color-primary;
	font-weight: bold;
}

a.theme-link {
	color: $theme-text-color-primary;
	text-decoration: underline;
    -webkit-text-decoration-color: rgba($theme-text-color-primary,0.3);
    text-decoration-color: rgba($theme-text-color-primary,0.3);
    &:hover {
	    color: $theme-color-primary;
	    -webkit-text-decoration-color: rgba($theme-color-primary,0.8);
	        text-decoration-color: rgba($theme-color-primary,0.8);
    }
}

.single-col-max-width {
	max-width: $single-col-max-width;
}

hr {
	border-color: rgba(0, 0, 0, 0.08);
}


.btn {
	font-weight: bold;
	padding: .375rem 1rem;
	height: 2.5rem;
	@include transition(all 0.4s ease-in-out);
	font-size: 1rem;
}

.btn:focus, .btn.focus {
	box-shadow: none !important;
}

.btn-primary {
	color: #fff;
}

.form-control {
	padding-top: 0.875rem;
	padding-bottom: 0.875rem;
	height: 2.5rem;
	border-color: $theme-border-color;
	@include placeholder ($theme-text-color-light);
}

.error {
	color: $theme-error;
}

.form-group {
	label.error {
		font-size: 0.875rem;
		padding: 0.5rem 0;
	}
	.btn {
		line-height: 1;
	}

}
.custom-select  {
	border-color: $theme-border-color;
	height: 2.5rem;
	color: $theme-text-color-light;
}


.theme-bg-light {
	background: $theme-bg-light !important;
}
.theme-bg-dark {
	background: $theme-bg-dark !important;
}
.table-bordered th, .table-bordered td {
	border-color: rgba(0, 0, 0, 0.08);
}
/* Header */
.header {
	position: fixed;
	left: 0;
	top: 0;
	height:100vh;
	width: $sidebar-width;
	background: $theme-color-primary;
	color: #fff;
	overflow-y: auto;
	
	/* Forefox scrollbar */
	scrollbar-color: rgba(0, 0, 0, 0.2) $theme-color-primary;
    scrollbar-width: thin;
    
    /* Chrome webkit scrollbar */
	
	&::-webkit-scrollbar {
	    width: 6px;
	    background-color: $theme-color-primary;
	} 
	
	&::-webkit-scrollbar-thumb {
	    background-color: rgba(0, 0, 0, 0.2);
	    @include border-radius(0.5rem);
	}
	
	

	.blog-name {
		font-weight: bold;
		font-size: 1.5rem;
		color: #fff;
		a {
			color: #fff;
			&:hover {
				text-decoration: none;
			}
		}
	}
	
	.btn-primary {
		background: rgba(0, 0, 0, 0.3);
		border-color: transparent;
		font-size: 1rem;
		&:hover {
			background: rgba(0, 0, 0, 0.4);
			border-color: transparent;
		}
	}
	
	
	.navbar {
	    padding: 1.5rem 1rem;
    }
    
    .navbar-dark {
	    
	    .navbar-toggler {
		    @include border-radius(2px);
		    @include opacity(0.8);
		    border: 1px solid rgba(256, 256, 256, 0.8);
		    padding: .25rem .6rem;
		    
		    &:hover {
			    @include opacity(1);
			    
		    }
	    }
	    
	    
	    .navbar-toggler-icon {
		   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(256,256,256, 1)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	    }
    }
    
    .nav-item {
	    font-weight: bold;
	    &.active {
		    .nav-link {
			   
		       color: rgba(0, 0, 0, 0.5); 
		       &:hover {
				    text-decoration: none;
			    }
		    }
		    
		    
	    }
	    .nav-link {
		    color: rgba(256, 256, 256, 0.8);
		    &:hover {
			    //text-decoration: underline;
			    color: rgba(0, 0, 0, 0.6); 
		    }
	    }
	    
	    .nav-link[aria-expanded="true"] {
		    color: rgba(0, 0, 0, 0.5);
	    }
	    
	    .dropdown-menu {
	        position: static;
	        background: darken($theme-color-primary, 5%);
	        border: none;
	        @include border-radius(0);
	        padding: 0;
	        margin: 0;
	        .dropdown-item {
		        color: #fff;
		        padding: 0.5rem;
		        font-size: 0.875rem;
		        padding-left: 2.25rem;
		        font-weight: bold;
		        &:hover, &:active, &:focus, &.active {
			        background: darken($theme-color-primary, 10%);
		        }
		        
	        }
        }
	    
	    
    }
    
    
    
}


.profile-section {
	.profile-image {
		max-width: 160px;
	}

	.bio {
		font-size: 0.875rem;
		a {
			color: #fff;
			text-decoration: underline;
			
			&:hover {
				color: rgba(0, 0, 0, 0.6);
				
			}
		}
	}
	
	
}
.social-list {

	a {
		width: 32px;
		height: 32px;
		padding-top: 5px;
		display: inline-block;
		text-align: center;
		@include border-radius(50%);
		@include transition(all 0.4s ease-in-out);
		background-color: #fff;
		&:hover {
			color: darken($theme-color-primary, 10%);
			background-color: rgba(256, 256, 256, 0.8);
		}
		
	}
}

/* Main wrapper */
.main-wrapper {
	margin-left: $sidebar-width;
	background: #fff;
	
	.container-single-col {
		max-width: $single-col-max-width;
	}
}

.cta-section {
	.heading {
		font-size: 2rem;
		font-weight: bold;
	}
}


.promo-section {
	margin-bottom: 40px;
	.promo-image {
		max-width: 320px;
	}
	.promo-section-inner {
		background: $theme-color-primary;
	}
}

/* Footer */
.footer{
	
	&.theme-bg-light {
		color: lighten($theme-text-color-secondary, 20%);
		a {
			color: lighten($theme-text-color-secondary, 20%);
			&:hover {
				color: lighten($theme-text-color-secondary, 15%);
			}
		}
	}
	&.theme-bg-dark {
		color: rgba(256, 256, 256, 0.7);
		a {
			color: rgba(256, 256, 256, 0.7);
			&:hover {
				color: rgba(256, 256, 256, 0.8);
			}
	
		}
	}
	
}

/* Dark Mode Toggle */
.dark-mode-toggle {
	.toggle-name {
		color: #fff;
		font-size: 0.875rem;
	}
	.toggle {
	    display: none;
	  
		+ .toggle-btn {
			outline: 0;
			display: block;
			width: 70px;
			height: 30px;
			position: relative;
			cursor: pointer;
			user-select: none;
			padding: 2px;
			@include transition(all 0.2s ease-in-out);
			//background: rgba(0, 0, 0, 0.05);
			border: 3px solid rgba(256, 256, 256, 0.4);
			@include border-radius(16px);
			
			
	         &::selection {
				background: none;
			}
			&:after,
			&:before {
				position: relative;
				display: block;
				content: "";
				width: 50%;
				height: 100%;
			}
	    
			&:after {
				left: 0;
				@include transition(all 0.2s ease-in-out);
			background: rgba(256, 256, 256, 0.4);
				content: "";
				@include border-radius(16px);
			}
	    
			&:before {
				display: none;
			}
		}
		
		&:checked + .toggle-btn {
			border: 4px solid rgba(256, 256, 256, 0.9);
			border: 3px solid $theme-color-primary;
			background: rgba(0, 0, 0, 0.2);
			&:after {
				left: 50%;
	             background: rgba(256, 256, 256, 0.9);
	             background: $theme-color-primary;
			}
		}

	}
	
}