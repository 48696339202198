/* ============= Theme Mixins ============= */
@mixin opacity ($opacity: 0.5) {
	-webkit-opacity: 	$opacity;
	-moz-opacity: 		$opacity;
	opacity: 		$opacity;
}

@mixin scale ($factor) {
	-webkit-transform: scale($factor);
	-moz-transform: 	 scale($factor);
	-ms-transform: 		 scale($factor);
	-o-transform: 		 scale($factor);
}


@mixin background-size ($string: contain) {
    -webkit-background-size: $string;
    -moz-background-size: $string;
    -o-background-size: $string;
    background-size: $string;
}

@mixin reset-box-sizing ($size:content-box) {
  &,
  *,
  *:before,
  *:after {
    -webkit-box-sizing: $size;
	-moz-box-sizing:    $size;
	box-sizing:         $size;
  }
}

@mixin placeholder ($color: #999) {
    &::-webkit-input-placeholder { /* WebKit browsers */
        color: $color;
    }
    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: $color;
    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: $color;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10+ */
        color: $color;
    }
}



